var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _vm.form.vehicleId
            ? _c(
                "a-form-model-item",
                { attrs: { label: "车辆自编号", prop: "vehicleId" } },
                [
                  _c("a-input", {
                    attrs: { disabled: "", placeholder: "请输入车辆自编号" },
                    model: {
                      value: _vm.form.vehicleId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "vehicleId", $$v)
                      },
                      expression: "form.vehicleId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆名称", prop: "name" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "车辆名称" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆牌号", prop: "licensePlateNum" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "车辆牌号" },
                model: {
                  value: _vm.form.licensePlateNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "licensePlateNum", $$v)
                  },
                  expression: "form.licensePlateNum",
                },
              }),
            ],
            1
          ),
          _vm.readType !== "summary"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "汇总日期", prop: "summaryDate" } },
                [
                  _c("a-input", {
                    attrs: { disabled: "", placeholder: "汇总日期" },
                    model: {
                      value: _vm.form.summaryDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "summaryDate", $$v)
                      },
                      expression: "form.summaryDate",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "智驾里程(km)", prop: "autoCtlOdom" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入智驾里程" },
                model: {
                  value: _vm.form.autoCtlOdom,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "autoCtlOdom", $$v)
                  },
                  expression: "form.autoCtlOdom",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "遥控里程(km)", prop: "remoteCtlOdom" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入遥控里程" },
                model: {
                  value: _vm.form.remoteCtlOdom,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remoteCtlOdom", $$v)
                  },
                  expression: "form.remoteCtlOdom",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "智驾时长", prop: "autoCtlTime" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入智驾时长" },
                model: {
                  value: _vm.form.autoCtlTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "autoCtlTime", $$v)
                  },
                  expression: "form.autoCtlTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "遥控时长", prop: "remoteCtlTime" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入遥控时长" },
                model: {
                  value: _vm.form.remoteCtlTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "remoteCtlTime", $$v)
                  },
                  expression: "form.remoteCtlTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "总里程(km)", prop: "odo" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入总里程" },
                model: {
                  value: _vm.form.odo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "odo", $$v)
                  },
                  expression: "form.odo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "总时长", prop: "ctlTime" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入总时长" },
                model: {
                  value: _vm.form.ctlTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ctlTime", $$v)
                  },
                  expression: "form.ctlTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "MPD", prop: "ctlTime" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入总时长" },
                model: {
                  value: _vm.form.MPD,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "MPD", $$v)
                  },
                  expression: "form.MPD",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "接管次数", prop: "takeOverDay" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入总时长" },
                model: {
                  value: _vm.form.ctlTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ctlTime", $$v)
                  },
                  expression: "form.ctlTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "mapid", prop: "mapid" } },
            [
              _c("a-input", {
                attrs: { disabled: "", placeholder: "请输入总时长" },
                model: {
                  value: _vm.form.ctlTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ctlTime", $$v)
                  },
                  expression: "form.ctlTime",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }