/*
 * @Auth: linjituan
 * @Date: 2022-01-06 18:44:58
 * @LastEditors: linjituan
 * @LastEditTime: 2022-01-20 16:19:54
 */
import request from '@/utils/request'

// 查询车辆日数据统计列表
export function listVehicleDailyData(query) {
  return request({
    url: '/iot/vehicleDailyData/list',
    method: 'get',
    params: query
  })
}

// 查询车辆日数据统计详细
export function getVehicleDailyData(id) {
  return request({
    url: '/iot/vehicleDailyData/' + id,
    method: 'get'
  })
}

// 新增车辆日数据统计
export function addVehicleDailyData(data) {
  return request({
    url: '/iot/vehicleDailyData',
    method: 'post',
    data: data
  })
}

// 修改车辆日数据统计
export function updateVehicleDailyData(data) {
  return request({
    url: '/iot/vehicleDailyData',
    method: 'put',
    data: data
  })
}

// 删除车辆日数据统计
export function delVehicleDailyData(id) {
  return request({
    url: '/iot/vehicleDailyData/' + id,
    method: 'delete'
  })
}

// 导出车辆日数据统计
export function exportVehicleDailyData(query) {
  return request({
    url: '/iot/vehicleDailyData/export',
    method: 'get',
    params: query
  })
}

// 入库统计智驾运行数据
export function summaryVehicleDailyData(query) {
  return request({
    url: '/iot/vehicleDailyData/summary',
    method: 'get',
    params: query
  })
}

// 查询智驾汇总数据统计列表
export function listSummary(query) {
  return request({
    url: '/iot/vehicleDailyData/listSummary',
    method: 'get',
    params: query
  })
}
